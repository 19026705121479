import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from 'App';
import registerServiceWorker from 'registerServiceWorker';
import {IntlProvider} from 'react-intl';
import i18n_en from 'i18n/en';
import i18n_pl from 'i18n/pl';
import i18n_lt from 'i18n/lt';
import i18n_de from 'i18n/de';
import i18n_lv from 'i18n/lv';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {version} from '../package.json';
import {CookiesProvider} from "react-cookie";

const messages = {
  en: i18n_en,
  pl: i18n_pl,
  lt: i18n_lt,
  de: i18n_de,
  lv: i18n_lv
};
const language = navigator.language.split(/[-_]/)[0];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: version,
});

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <BrowserRouter>
      <CookiesProvider>
        <App/>
      </CookiesProvider>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById('root')
);
registerServiceWorker();
