import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
  AUDIO_OR_CAMERA_DISABLED, CLAIM_NOT_ACTIVE,
  REGULATIONS_REJECTED,
  SESSION_NOT_FOUND,
  SESSION_OPERATOR_NOT_AVAILABLE, TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR,
  UNEXPECTED,
  UNEXPECTED_DISCONNECT,
  WEBRTC_NOT_SUPPORTED,
} from './ErrorTypes';
import styles from './Error.module.scss';
import {Button} from "reactstrap";

const Error = ({error, onReconnect}) => {
  const exception = mapError(error)

  function mapError(error) {
    console.log("error")
    console.log(error)
    const found = [
      REGULATIONS_REJECTED,
      WEBRTC_NOT_SUPPORTED,
      SESSION_NOT_FOUND,
      SESSION_OPERATOR_NOT_AVAILABLE,
      CLAIM_NOT_ACTIVE,
      UNEXPECTED_DISCONNECT,
      AUDIO_OR_CAMERA_DISABLED,
      TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR,
      UNEXPECTED
    ].some((t) => t === error);
    if (found) {
      return error;
    } else {
      return UNEXPECTED;
    }
  }

  function isAllowedToReconnect() {
    return [
      UNEXPECTED_DISCONNECT,
      AUDIO_OR_CAMERA_DISABLED,
      UNEXPECTED,
      SESSION_OPERATOR_NOT_AVAILABLE,
      TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR,
      CLAIM_NOT_ACTIVE
    ].some((t) => t === exception) && onReconnect;
  }

  return (
    <div>
      <p className={`${styles.text} text-center`}>
        <FormattedMessage id={`error.${exception.toLowerCase()}`}/>
      </p>

      {isAllowedToReconnect() && (
        <Button className="mt-5" block={true} color="success"
                onClick={onReconnect}>
          <FormattedMessage id="call.again"/>
        </Button>
      )}
    </div>
  );
}

export default Error;
