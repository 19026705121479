import React from 'react';
import {FormattedDate, FormattedMessage, FormattedTime} from 'react-intl';
import LinkButton from "../common/link-button";
import {Redirect} from "react-router-dom";

const Assessment = (props) => {
  const sessionId = props.match.params.sessionId;
  const mainAssessment = props.mainAssessment
  const allAssessments = props.allAssessments

  return (
    <div>
      {(mainAssessment.scheduledAssessmentDate !== null) &&
      <Redirect from={`/${sessionId}/assessment`} to={`/${sessionId}/aggregated`}/>
      }
      <p className={`text-center`}>
        <FormattedMessage id="timeslot.claim_details"/>
      </p>

      <p className={`text-center`}>
        <strong>
          {mainAssessment.claimDetails}
        </strong>
      </p>

      {(mainAssessment.scheduledAssessmentDate !== null) &&
      <p className={`text-center`}>
        <FormattedMessage id="timeslot.scheduled_assessment_date" values={{
          details: mainAssessment.claimDetails
        }}/>
      </p>
      }
      {(mainAssessment.scheduledAssessmentDate !== null) &&
      <p className={`text-center`}>
        <strong>
          <FormattedDate value={mainAssessment.scheduledAssessmentDate} day="2-digit" month="2-digit" year="numeric"/> <FormattedTime value={mainAssessment.scheduledAssessmentDate}/>
        </strong>
      </p>
      }

      <LinkButton
        className="mt-5"
        color="success"
        textid={(mainAssessment.scheduledAssessmentDate === null) ? "timeslot.schedule_assessment_date" : "timeslot.reschedule_assessment_date"}
        to={`/${sessionId}/timeslots/${sessionId}`}
      />

      {(allAssessments.length > 0) &&
      <LinkButton
        className="mt-5"
        color="success"
        textid="assessments.browse"
        to={`/${sessionId}/aggregated`}
      />
      }

    </div>
  );
};

export default Assessment;
