import React, { Component } from 'react';

import SignalHandler from './index';
import { TORCH, TORCH_COMPLETED, TORCH_ERROR } from './SignalTypes';
import { withSession } from 'components/common/session-context';

class TorchHandler extends Component {
  render() {
    return <SignalHandler signalName={TORCH} onSignal={this.handleSignal} />;
  }

  handleSignal = (signal) => {
    const { session, publisher } = this.props;

    if (!publisher) {
      return;
    }

    publisher
      .setTorch(signal.data === 'ON')
      .then(() => {
        session.signal({ type: TORCH_COMPLETED, data: signal.data });
      })
      .catch((error) => {
        session.signal({ type: TORCH_ERROR, data: error });
      });
  };
}

export default withSession(TorchHandler);
