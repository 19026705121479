import React, { Component } from 'react';
import { Events } from 'vi2-webrtc';

import { withSession } from 'components/common/session-context';

class Subscriber extends Component {
  constructor() {
    super();

    this.subscriber = React.createRef();
  }

  componentDidMount() {
    const { session } = this.props;

    session.on(Events.STREAM_CREATED, (event) => {
      const { subscribeToVideo } = this.props;
      const props = { subscribeToVideo };
      session.subscribe(event.stream, this.subscriber.current, props);
    });
  }

  render() {
    return <div ref={this.subscriber} />;
  }
}

export default withSession(Subscriber);
