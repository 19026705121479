import { Component } from 'react';
import { withSession } from 'components/common/session-context';

class SignalHandler extends Component {
  componentDidMount() {
    const { session, signalName } = this.props;
    session.on(`signal:${signalName}`, this.handleSignal);
  }

  render() {
    return null;
  }

  handleSignal = (signal) => {
    const { onSignal } = this.props;
    onSignal(signal);
  };
}

export default withSession(SignalHandler);
