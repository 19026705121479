import React, { Component } from 'react';

import SignalHandler from '.';
import { TAKE_PHOTO, TAKE_PHOTO_COMPLETED, TAKE_PHOTO_ERROR } from './SignalTypes';
import { savePhoto } from 'api';
import { withSession } from 'components/common/session-context';
import parseResolution from './parseResolution';

class TakePhotoSignalHandler extends Component {
  render() {
    return <SignalHandler signalName={TAKE_PHOTO} onSignal={this.handleSignal} />;
  }

  handleSignal = () => {
    const { publisher } = this.props;

    if (!publisher) {
      return;
    }

    const { width, height } = parseResolution(process.env.REACT_APP_PHOTO_RESOLUTION);
    publisher
      .takePhoto({ imageWidth: width, imageHeight: height })
      .then((blob) => {
        const { sessionId } = this.props;
        return savePhoto(sessionId, blob);
      })
      .then(() => {
        const { session } = this.props;
        session.signal({ type: TAKE_PHOTO_COMPLETED });
      })
      .catch((error) => {
        const { session } = this.props;
        session.signal({ type: TAKE_PHOTO_ERROR, data: error });
      });
  };
}

export default withSession(TakePhotoSignalHandler);
