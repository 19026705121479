import React, {Component} from 'react';
import {Events} from 'vi2-webrtc';

import SessionContext, {withSession} from 'components/common/session-context';
import Connecting from 'components/session/connecting';
import {Redirect} from 'react-router-dom';
import {isWebRtcSupported} from 'components/session/LivestreamingSupport';
import {UNEXPECTED_DISCONNECT, WEBRTC_NOT_SUPPORTED} from 'components/error/ErrorTypes';
import {CONNECTION_CLOSED} from './signal-handler/SignalTypes';
import SignalHandler, {SwitchCameraHandler, TakePhotoSignalHandler, TorchHandler,} from './signal-handler';
import provideSession from './provideSession';
import LiveStreaming from './live-streaming';
import {getBackCamera, promptForCameraPermission} from './camera-tools';

export class Session extends Component {
  constructor(props) {
    super(props);

    this.state = {streamCreated: false};
    this.sessionId = props.match.params.sessionId;
    this.signallingServerUrl = props.signallingServerUrl;
  }

  async componentDidMount() {
    if (!isWebRtcSupported) {
      this.setState({error: WEBRTC_NOT_SUPPORTED});
      return;
    }

    try {
      const session = await provideSession(this.sessionId, this.signallingServerUrl);

      session.on(Events.STREAM_CREATED, () => {
        this.setState((prevState) => ({...prevState, streamCreated: true}));
      });

      session.on(Events.STREAM_DESTROYED, () => {
        this.setState((prevState) => ({...prevState, streamCreated: false}));
      });

      session.on(Events.CONNECTION_DESTROYED, () => {
        this.state.session.unpublish();
        this.state.session.disconnect();
        this.setState((prevState) => ({
          ...prevState,
          error: UNEXPECTED_DISCONNECT,
        }));
      });

      session.connect();

      // detect back camera using camera labels
      await promptForCameraPermission();
      const backCamera = await getBackCamera();

      this.setState((prevState) => ({
        ...prevState,
        session: session,
        videoSource: backCamera && backCamera.deviceId,
        facingMode: backCamera ? undefined : 'environment',
      }));
    } catch (error) {
      this.setState({error});
    }
  }

  render() {
    if (this.state.error) {
      return <Redirect to={`/${this.sessionId}/error?type=${this.state.error}`}/>;
    }

    if (!this.state.session) return null;

    return (
      <SessionContext.Provider value={this.state.session}>
        <Connecting/>
        <LiveStreaming
          videoSource={this.state.videoSource}
          facingMode={this.state.facingMode}
          onHangUp={this.finishCall}
          streamCreated={this.state.streamCreated}
          onPublisherCreated={this.handlePublisherCreated}
        />
        <SignalHandler
          signalName={CONNECTION_CLOSED}
          onSignal={this.handleConnectionClosedSignal}
        />
        {this.state.publisher && (
          <TakePhotoSignalHandler publisher={this.state.publisher} sessionId={this.sessionId}/>
        )}
        {this.state.publisher && (
          <SwitchCameraHandler
            publisher={this.state.publisher}
            onCameraSwitch={this.handleCameraSwitch}
          />
        )}
        {this.state.publisher && <TorchHandler publisher={this.state.publisher}/>}
      </SessionContext.Provider>
    );
  }

  finishCall = () => {
    this.state.session.unpublish();
    this.state.session.disconnect();
    this.props.history.push(`/${this.sessionId}/finish`);
  };

  handlePublisherCreated = (publisher) => {
    this.setState((prevState) => ({...prevState, publisher}));
  };

  handleConnectionClosedSignal = () => {
    this.finishCall();
  };

  handleCameraSwitch = (videoSource) => {
    this.setState((prevState) => ({...prevState, videoSource}));
  };
}

export default withSession(Session);
