import {FormattedDate, FormattedTime} from "react-intl";
import React from "react";
import LinkButton from "../common/link-button";
import styles from "./Timeslot.module.scss";

const AssessmentDetails = ({sessionId, currentSessionId, claimDetails, scheduledAssessmentDate, isActive, claimNumber}) => {
  return (
      <div className={`${styles.assessment_item}`}>
        <div className={`text-center mb-2`}>
          <strong>
            {claimDetails}
          </strong>
        </div>

        <div className={`text-center mb-2`} >
          <strong>
            {claimNumber}
          </strong>
        </div>

        {(scheduledAssessmentDate !== null) &&
        <div className={`text-center`}>
          <strong>
            <FormattedDate value={scheduledAssessmentDate} day="2-digit" month="2-digit" year="numeric"/> <FormattedTime
            value={scheduledAssessmentDate}/>
          </strong>
        </div>
        }

        {(isActive === true) &&
        <LinkButton className={`text-center mt-3`}
                    color="success"
                    textid={(scheduledAssessmentDate === null) ? "timeslot.schedule_assessment_date" : "timeslot.reschedule_assessment_date"}
                    to={`/${sessionId}/timeslots/${currentSessionId}`}
        />
        }
        {(isActive !== true) &&
        <LinkButton className={`text-center mt-3 disabled`}
                    color="secondary"
                    textid={"timeslot.assessment_finished"}
                    to={`/${sessionId}/timeslots/${currentSessionId}`}
        />
        }
      </div>
  )
};

export default AssessmentDetails;
