import React, { Component } from 'react';
import { Events } from 'vi2-webrtc';

import SignalHandler from './index';
import {
  ENABLE_SWITCH_CAMERA,
  SWITCH_CAMERA,
  SWITCH_CAMERA_COMPLETED,
  SWITCH_CAMERA_ERROR
} from './SignalTypes';
import { withSession } from 'components/common/session-context';
import { getNextCamera, canSwitchCamera } from '../camera-tools';

class SwitchCameraHandler extends Component {
  constructor() {
    super();

    this.state = {
      canSwitchCamera: false
    };
  }

  componentDidMount() {
    const { session } = this.props;

    session.on(Events.STREAM_CREATED, () => {
      canSwitchCamera().then((canSwitchCamera) => {
        if (canSwitchCamera) {
          const { session } = this.props;
          session.signal({ type: ENABLE_SWITCH_CAMERA });
        }
        this.setState((prevState) => ({
          ...prevState,
          canSwitchCamera
        }));
      });
    });
  }

  render() {
    return (
      this.state.canSwitchCamera && (
        <SignalHandler signalName={SWITCH_CAMERA} onSignal={this.handleSignal} />
      )
    );
  }

  handleSignal = () => {
    const { session, publisher, onCameraSwitch } = this.props;

    const currentCameraLabel = publisher.element.srcObject.getVideoTracks()[0].label;

    getNextCamera(currentCameraLabel)
      .then((switchedCamera) => {
        if (typeof onCameraSwitch === 'function') {
          onCameraSwitch(switchedCamera.deviceId);
        }
        session.signal({ type: SWITCH_CAMERA_COMPLETED });
      })
      .catch((error) => {
        session.signal({ type: SWITCH_CAMERA_ERROR, data: error });
      });
  };
}

export default withSession(SwitchCameraHandler);
