import {initSession, PeerRoles} from 'vi2-webrtc';
import {UNEXPECTED} from '../error/ErrorTypes';

export default async function provideSession(sessionId, signallingServerUrl) {
  try {
    const role = PeerRoles.CALLER;

    return initSession({sessionId, role, signallingServerUrl});
  } catch (error) {
    throw UNEXPECTED;
  }
}

