import {executeScheduleAssessmentDate} from "./ExecuteScheduleAssessmentDate";
import {toastService} from "../toast/Toast";
import {FormattedDate} from "react-intl";
import {Button} from "reactstrap";
import React, {useState} from "react";
import Error from "../error";

const Timeslot = ({onChangeScheduledAssessmentDate, currentSessionId, date, slots}) => {
  const [error, setError] = useState(null);

  async function scheduleAssessmentDate(e) {
    try {
      await executeScheduleAssessmentDate(currentSessionId, date + "T" + e)
      await toastService(date + "T" + e)
    } catch (e) {
      setError(e)
    }
    onChangeScheduledAssessmentDate()
  }

  if(error) {
    return (
      <Error error = {error} onReconnect={() => setError(null)}/>
    )
  }

  return (
    <div>
      <p className="text-center">
        <strong>
          <FormattedDate value={date} day="2-digit" month="2-digit" year="numeric"/>
        </strong>
      </p>
      <p className="text-center">
        {
          slots.map(function (item) {
            return (
              <Button className="m-3"
                      color="success"
                      key={item}
                      onClick={() => scheduleAssessmentDate(item)}
              >{item}</Button>
            );
          })}
      </p>
    </div>
  );
}

export default Timeslot;
