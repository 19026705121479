import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const LinkButton = (props) => (
  <Button {...props} block={true} tag={Link}>
    <FormattedMessage id={props.textid} />
  </Button>
);

export default LinkButton;
