import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import "./Toast.css"

toast.configure()

const ToastMessage = ({date}) => {
  return (
    <div>
      <FormattedMessage id="assessment.confirmation"/>
      <br/>
      <strong>
        <FormattedDate value={date} day="2-digit" month="2-digit" year="numeric"/> <FormattedTime value={date}/>
      </strong>
      <p/>
      <FormattedMessage id="browser.close"/>
    </div>
  )
}

export async function toastService(date) {
  toast.success(
    <ToastMessage
      date={date}
    />, { className: 'toast-success', autoClose: false })
}



