import React, { Component } from 'react';
import { initPublisher, Events } from 'vi2-webrtc';

import { withSession } from 'components/common/session-context';
import styles from './Publisher.module.scss';

function shouldRecreatePublisher(prevProps, currProps) {
  const hasPropertyChanged = (key, defaultValue) => {
    const prevValue = prevProps[key] === undefined ? defaultValue : prevProps[key];
    const currValue = currProps[key] === undefined ? defaultValue : currProps[key];
    return prevValue !== currValue;
  };

  // add properties that require publisher to be unsubscribed here
  const forceUnpublish = [{ key: 'publishVideo', defaultValue: true }, { key: 'videoSource' }];

  return forceUnpublish.some((prop) => hasPropertyChanged(prop.key, prop.defaultValue));
}

class Publisher extends Component {
  constructor(props) {
    super(props);
    this.publisherRef = React.createRef();
    this.state = { connected: false };
  }

  componentDidUpdate(prevProps) {
    if (!this.publisher) {
      return;
    }

    if (shouldRecreatePublisher(prevProps, this.props)) {
      this.destroyPublisher();
      this.createPublisher();
    }
  }

  componentDidMount() {
    this.createPublisher();
  }

  render() {
    return (
      <div
        ref={this.publisherRef}
        className={`${styles.publisher} ${
          this.props.visible && this.props.publishVideo ? 'd-block' : 'd-none'
        }`}
      />
    );
  }

  destroyPublisher() {
    const { session } = this.props;
    session.unpublish(this.publisher);
  }

  createPublisher() {
    const { session, publishVideo, videoSource, facingMode } = this.props;

    this.publisher = initPublisher(this.publisherRef.current, {
      publishVideo,
      videoSource,
      facingMode,
    });

    const { onPublisherCreated } = this.props;
    if (typeof onPublisherCreated === 'function') {
      onPublisherCreated(this.publisher);
    }

    if (session.connection) {
      session.publish(this.publisher);
    } else {
      session.on(Events.SESSION_CONNECTED, () => {
        session.publish(this.publisher);
      });
    }
  }
}

export default withSession(Publisher);
