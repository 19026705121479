import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import chrome from 'images/chrome.png';
import firefox from 'images/firefox.png';
import styles from './BrowserSelection.module.scss';

const BrowserSelection = (props) => {
  const onBrowserChecked = props.onBrowserChecked;
  const browserSupported = checkIfBrowserSupported();

  useEffect(() => {
      if (browserSupported) {
        onBrowserChecked()
      }
    }
  )

  const redirectUrl = `intent:${window.location
    .toString()
    .replace(window.location.protocol, '')
    .replace('browser-selection', 'session')}#Intent;scheme=${window.location.protocol.replace(
    ':',
    ''
  )}`;

  function checkIfBrowserSupported() {
    const isFbBrowser = navigator.userAgent.indexOf('FB') !== -1;
    const isSamsungBrowser = navigator.userAgent.indexOf('SamsungBrowser') !== -1;
    const isWebview = navigator.userAgent.indexOf('wv') !== -1;
    const isXiaoMiMiuiBrowser = navigator.userAgent.indexOf('MiuiBrowser') !== -1;

    return !isFbBrowser && !isSamsungBrowser && !isWebview && !isXiaoMiMiuiBrowser;
  }


  function openInChrome() {
    window.location.href = `${redirectUrl};package=com.android.chrome;end`;
  }

  function openInFirefox() {
    window.location.href = `${redirectUrl};package=org.mozilla.firefox;end`;
  }

  if(browserSupported) {
    return null;
  }

  return (
    <div className="text-center">
      <p className="mb-0">
        <FormattedMessage id="browser.selection.title" />
      </p>

      <p>
        <FormattedMessage id="browser.selection.description" />
      </p>

      <img
        src={chrome}
        alt="Chrome"
        className={`${styles.icon} img-fluid pr-5`}
        onClick={openInChrome}
      />
      <img
        src={firefox}
        alt="Firefox"
        className={`${styles.icon} img-fluid`}
        onClick={openInFirefox}
      />
    </div>
  );
};

export default BrowserSelection;
