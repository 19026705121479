import React, {useEffect, useState} from 'react';
import Welcome from 'components/welcome';
import BrowserSelection from 'components/browser-selection/BrowserSelection';
import Session from 'components/session';
import Finish from "components/finish";
import {Redirect, Route, Switch} from 'react-router-dom';
import Error from "../error";
import {BROWSER_CHECKED, NEW, SESSION_LOADED, TERMS_ACCEPTED} from "./Statuses";
import {TIMESLOT_SELECTION, VIDEO_INSPECTION} from "./InspectionTypes";
import {fetchSession} from "./FetchSession";
import Assessment from "../timeslot/Assessment";
import AggregatedAssessments from "../timeslot/AggregatedAssessments";
import Timeslots from "../timeslot/Timeslots";

const AppBody = ({sessionId}) => {
  const [session, setSession] = useState({
    sessionType: null,
    signallingServerUrl: null,
    showTerms: null,
    scheduledAssessmentDate: null,
    claimDetails: null,
    mainAssessment: {
      claimActive: false,
      claimDetails: null,
      claimNumber: null,
      scheduledAssessmentDate: null,
      sessionId: null
    },
    allAssessments: []
  })

  const [error, setError] = useState(null);

  const [status, setStatus] = useState(NEW)

  async function retrieveSession() {
    try {
      const fetchedSession = await fetchSession(sessionId)
      setSession(fetchedSession)
      setStatus(SESSION_LOADED)
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    if (!error) {
      retrieveSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, error])

  if (error) {
    return (
      <Error error={error} onReconnect={() => setError(null)}/>
    )
  }

  if (status === NEW) {
    return null;
  }

  if (status === SESSION_LOADED) {
    return (
      <Switch>
        <Route path="/:sessionId/welcome" render={(props) => (
          <Welcome {...props}
                   showTerms={session.showTerms}
                   onAcceptTerms={() => setStatus(TERMS_ACCEPTED)}/>)
        }/>
        <Redirect from="/:sessionId" to="/:sessionId/welcome"/>
      </Switch>
    );
  }

  if (status === TERMS_ACCEPTED) {
    return (
      <Switch>
        <Route path="/:sessionId/browser-selection" render={(props) => (
          <BrowserSelection {...props}
                            showTerms={session.showTerms}
                            onBrowserChecked={() => setStatus(BROWSER_CHECKED)}/>)
        }/>
        <Redirect from="/:sessionId" to="/:sessionId/browser-selection"/>
      </Switch>
    );
  }

  if (status === BROWSER_CHECKED && session.sessionType === TIMESLOT_SELECTION) {
    return (
      <Switch>
        <Route exact path="/:sessionId/assessment" render={(props) => (
          <Assessment {...props}
                      scheduledAssessmentDate={session.scheduledAssessmentDate}
                      claimDetails={session.claimDetails}
                      mainAssessment={session.mainAssessment}
                      allAssessments={session.allAssessments}
          />)
        }/>
        <Route exact path="/:sessionId/timeslots/:currentSessionId" render={(props) => (
          <Timeslots {...props}
                     onChangeScheduledAssessmentDate={() => retrieveSession()}
          />)
        }/>
        <Route exact path="/:sessionId/aggregated" render={(props) => (
          <AggregatedAssessments {...props}
                      allAssessments={session.allAssessments}
          />)
        }/>
        <Redirect from="/:sessionId" to="/:sessionId/assessment"/>
      </Switch>
    );

  }

  if (status === BROWSER_CHECKED && session.sessionType === VIDEO_INSPECTION) {
    return (
      <Switch>
        <Route path="/:sessionId/session" render={(props) => (
          <Session {...props} signallingServerUrl={session.signallingServerUrl}/>)
        }/>
        <Route path="/:sessionId/finish" component={Finish}/>
        <Redirect from="/:sessionId" to="/:sessionId/session"/>
      </Switch>
    );
  }

  return null
}

export default AppBody;
