import React, { Component } from 'react';
import { Events } from 'vi2-webrtc';

import { withSession } from 'components/common/session-context';
import { FormattedMessage } from 'react-intl';
import Spinner from 'components/common/spinner';
import styles from './Connecting.module.scss';

class Connecting extends Component {
  constructor() {
    super();
    this.state = { connecting: true };
  }

  componentDidMount() {
    this.props.session.on(Events.STREAM_CREATED, () => {
      this.setState(() => ({ connecting: false }));
    });
  }

  render() {
    if (!this.state.connecting) return null;

    return (
      <div className={`${styles.connecting} text-center`}>
        <FormattedMessage id="connecting.invitation" />
        <div className="py-3">
          <Spinner />
        </div>
        <FormattedMessage id="connecting.expect" />
      </div>
    );
  }
}

export default withSession(Connecting);
