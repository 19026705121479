import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {Button} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import RegulationsUrl from './RegulationsUrl';
import {REGULATIONS_REJECTED} from "../error/ErrorTypes";
import Error from "../error";

const regulationsAcceptedCookieName = 'regulationsAccepted';
const regulationsAcceptedCookieValidInSeconds = 3 * 24 * 60 * 60;

const Welcome = (props) => {
  const [cookies, setCookie] = useCookies([regulationsAcceptedCookieName]);

  const [error, setError] = useState(null);

  const sessionId = props.match.params.sessionId;

  const onAcceptTerms = props.onAcceptTerms;

  const termsAlreadyAccepted = cookies.hasOwnProperty(regulationsAcceptedCookieName) || !props.showTerms

  useEffect(() => {
      if (termsAlreadyAccepted) {
        onAcceptTerms()
      }
    }
  )

  if (termsAlreadyAccepted) {
    return null;
  }

  function handleRegulationsAccept() {
    setAcceptTermsCookie();
    onAcceptTerms();
  }

  function handleRegulationsReject() {
    setError(REGULATIONS_REJECTED)
  }


  function setAcceptTermsCookie() {
    setCookie(regulationsAcceptedCookieName, regulationsAcceptedCookieName, {
      maxAge: regulationsAcceptedCookieValidInSeconds
    })
  }

  if(error) {
    return (
      <Error error = {error}/>
    )
  }

  return (
    <div className="text-center">
      <p>
        <FormattedMessage
          id="welcome.regulationsAccept"
          values={{
            regulations: <RegulationsUrl sessionId={sessionId}/>
          }}
        />
      </p>

      <div className="mt-5">
        <Button color="success" block={true} onClick={handleRegulationsAccept}>
          <FormattedMessage id="welcome.accept"/>
        </Button>

        <Button color="danger" block={true} className="mt-4" onClick={handleRegulationsReject}>
          <FormattedMessage id="welcome.reject"/>
        </Button>
      </div>
    </div>
  );

}

export default Welcome;
