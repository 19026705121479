import {getSession} from "../../api";
import {CLAIM_NOT_ACTIVE, SESSION_NOT_FOUND, SESSION_OPERATOR_NOT_AVAILABLE, UNEXPECTED} from "../error/ErrorTypes";

export async function fetchSession(sessionId) {
  try {
    const result = await getSession(sessionId)
    const data = result.data
    console.log(data)
    return {
      sessionType: data.type,
      signallingServerUrl: data.signallingServerUrl,
      showTerms: data.showTerms,
      scheduledAssessmentDate: data.scheduledAssessmentDate,
      claimDetails: data.claimDetails,
      claimName: data.claimName,
      mainAssessment: data.mainAssessment,
      allAssessments: data.allAssessments
    }
  } catch (error) {
    if (error.status === 400 && error.data !== undefined && error.data.message !== undefined) {
      switch (error.data.message) {
        case "ERROR.SESSION_NOT_FOUND":
          throw SESSION_NOT_FOUND;
        case "ERROR.SESSION_OPERATOR_NOT_AVAILABLE":
          throw  SESSION_OPERATOR_NOT_AVAILABLE;
        case "ERROR.CLAIM_NOT_ACTIVE":
          throw  CLAIM_NOT_ACTIVE;
        default:
      }
    }
    throw UNEXPECTED
  }

}
