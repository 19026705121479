import React from 'react';

import styles from './HangUpButton.module.scss';
import hangup from 'images/hangup.png';

const HangUpButton = (props) => (
  <img className={styles.hangup} src={hangup} alt="Hang up" onClick={props.onClick} />
);

export default HangUpButton;
