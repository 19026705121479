import React, {Component} from 'react';
import Error from "./Error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null};
    this.sessionId = props.sessionId;
  }

  componentDidCatch(error, info) {
    console.log(error)
    this.setState({hasError: true, error: error});
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error error={this.state.error} onReconnect={() => this.setState({hasError: false, error: null})}/>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary;
