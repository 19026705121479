import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {fetchAvailability} from "./FetchAvailability";
import Timeslot from "./Timeslot";

const Timeslots = (props) => {
  const sessionId = props.match.params.sessionId;
  const currentSessionId = props.match.params.currentSessionId;
  const [timeslots, setTimeslots] = useState([])
  const [error, setError] = useState(null);
  const onChangeScheduledAssessmentDate = props.onChangeScheduledAssessmentDate;

  useEffect(() => {
    async function retrieveAvailability() {
      try {
        const fetchedTimeslots = await fetchAvailability(currentSessionId)
        setTimeslots(fetchedTimeslots)
      } catch (e) {
        setError(e)
      }
    }

    if (!error) {
      retrieveAvailability()
    }
  }, [currentSessionId, sessionId, error])

  return (
    <div>
      <p className={`text-center`}>
        <FormattedMessage id="timeslot.choose_assessment_date"/>
      </p>
      {
        timeslots.map(function (item) {
          return (
            <Timeslot
              key={`date${item.date}`}
              sessionId={sessionId}
              currentSessionId={currentSessionId}
              date={item.date}
              slots={item.slots}
              onChangeScheduledAssessmentDate={onChangeScheduledAssessmentDate}>
              {item.date}
            </Timeslot>);
        })}
    </div>
  );
};

export default Timeslots;


