import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

export function savePhoto(sessionId, blob) {
  const formData = new FormData();
  formData.append('file', blob);

  return api.post(`assessment/session/${sessionId}/photo`, formData);
}

export function getSession(sessionId) {
  return api.get(`assessment/session/${sessionId}`);
}

export function getShowTerm(sessionId) {
  return api.get(`brand/${sessionId}/showTerm`);
}

export function getAvailability(sessionId) {
  return api.get(`availability/session/${sessionId}`);
}

export function scheduleAssessmentDate(sessionId, date) {
  return api.post(`claims/session/${sessionId}`, {scheduledDate: date});
}


