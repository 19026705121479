import React from 'react';
import SessionContext from './SessionContext';

const withSession = (Component) => {
  return function WithSession(props, context) {
    return (
      <SessionContext.Consumer>
        {(session) => <Component {...props} session={session} />}
      </SessionContext.Consumer>
    );
  };
};

export default withSession;
