import isWebView from './webview-detection';

export async function getVideoDevices() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((d) => /^videoinput$/i.test(d.kind));
}

export async function getNextCamera(currentCameraLabel) {
  const cameras = await getVideoDevices();
  const prevIndex = cameras.findIndex((camera) => camera.label === currentCameraLabel);
  const index = prevIndex < cameras.length - 1 ? prevIndex + 1 : 0;
  return cameras[index];
}

export async function canSwitchCamera() {
  const cameras = await getVideoDevices();
  return cameras.length > 1;
}

export async function getBackCamera() {
  const cameras = await getVideoDevices();

  const byLabel = () =>
    cameras
      .filter((camera) => /facing back/.test(camera.label))
      .sort((c1, c2) => c1.label.localeCompare(c2.label))[0];
  // for web view return second camera because labels are empty
  const forWebView = () => (isWebView() && cameras.length > 1 ? cameras[1] : undefined);
  return byLabel() || forWebView();
}

export async function promptForCameraPermission() {
  const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  stream.getTracks().forEach((track) => {
    track.stop();
  });
}
