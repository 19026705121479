import React from 'react';

import Publisher from 'components/session/publisher';
import Subscriber from 'components/session/subscriber';
import HangUpButton from 'components/common/hang-up-button';

/* TODO - start publishing only when other party joins */
/* Explanation: currently in vi2-webrtc there is no functionality to start publishing when other party joins */
/* So we start video immediately but RTC connection is established when other party joins */
/* This is a workaround and should be removed when vi2-webrtc adds functionality for "lazy" publishing */
const LiveStreaming = (props) => (
  <div>
    <Publisher
      publishVideo={true}
      facingMode={props.facingMode}
      videoSource={props.videoSource}
      onPublisherCreated={props.onPublisherCreated}
      visible={props.streamCreated} /* HACK - publish video but do not display element until other party joins */
    />
    {props.streamCreated && <HangUpButton onClick={props.onHangUp} />}
    <Subscriber subscribeToVideo={false} />
  </div>
);

export default LiveStreaming;
