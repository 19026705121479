export default function parseResolution(resolution) {
  const parts = resolution.split('x', 2);
  if (parts.length !== 2) {
    throw new Error(`Invalid resolution format '${resolution}'. Should be width x height.`);
  }
  return {
    width: parseInt(parts[0], 10),
    height: parseInt(parts[1], 10)
  };
}
