import React from 'react';
import { FormattedMessage } from 'react-intl';

const RegulationsUrl = ({ sessionId }) => (
  <a
    href={`${process.env.REACT_APP_API_URL}/brand/${sessionId}/term`}
    target="_blank"
    download
    className="text-lowercase"
    rel="noreferrer"
  >
    <FormattedMessage id="all.regulations" />
  </a>
);

export default RegulationsUrl;
