import {getAvailability} from "../../api";
import {CLAIM_NOT_ACTIVE, SESSION_NOT_FOUND, UNEXPECTED} from "../error/ErrorTypes";

export async function fetchAvailability(sessionId) {
  try {
    const result = await getAvailability(sessionId)
    const data = result.data
    console.log(data)
    return data;
  } catch (error) {
    if (error.status === 400 && error.data !== undefined && error.data.message !== undefined) {
      switch (error.data.message) {
        case "ERROR.SESSION_NOT_FOUND":
          throw SESSION_NOT_FOUND;
        case "ERROR.CLAIM_NOT_ACTIVE":
          throw CLAIM_NOT_ACTIVE;
        default:
      }
    }
    throw UNEXPECTED
  }

}
