import {scheduleAssessmentDate} from "../../api";
import {
  CLAIM_NOT_ACTIVE,
  SESSION_NOT_FOUND,
  TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR,
  UNEXPECTED
} from "../error/ErrorTypes";

export async function executeScheduleAssessmentDate(sessionId, date) {
  try {
    await scheduleAssessmentDate(sessionId, date)
  } catch (error) {
    if (error.status === 400 && error.data !== undefined && error.data.message !== undefined) {
      switch (error.data.message) {
        case "ERROR.SESSION_NOT_FOUND":
          throw SESSION_NOT_FOUND;
        case "ERROR.TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR":
          throw  TIMESLOT_NOT_AVAILABLE_FOR_ANY_OPERATOR;
        case "ERROR.CLAIM_NOT_ACTIVE":
          throw  CLAIM_NOT_ACTIVE;
        default:
      }
    }
    throw UNEXPECTED
  }

}
