import React from 'react';
import {Container} from 'reactstrap';
import {useRouteMatch} from 'react-router-dom';
import Header from "components/common/header";
import AppBody from 'components/body/AppBody';
import ErrorBoundary from "./components/error/ErrorBoundary";
import Error from "./components/error";
import {SESSION_NOT_FOUND} from "./components/error/ErrorTypes";
import {ToastContainer} from "react-toastify";

const App = () => {
  const match = useRouteMatch("/:sessionId");

  if (match) {
    return (
      <Container className="py-4">
        <ToastContainer/>
        <Header sessionId={match.params.sessionId}/>
        <ErrorBoundary sessionId={match.params.sessionId}>
          <AppBody sessionId={match.params.sessionId}/>
        </ErrorBoundary>
      </Container>
    );
  } else {
    return (
      <Container className="py-4">
        <Error error={SESSION_NOT_FOUND}/>
      </Container>
    )
  }
};

export default App;
