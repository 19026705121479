export const TAKE_PHOTO = 'TAKE_PHOTO';
export const TAKE_PHOTO_COMPLETED = 'TAKE_PHOTO_COMPLETED';
export const TAKE_PHOTO_ERROR = 'TAKE_PHOTO_ERROR';
export const CONNECTION_CLOSED = 'CONNECTION_CLOSED';
export const ENABLE_SWITCH_CAMERA = 'ENABLE_SWITCH_CAMERA';
export const SWITCH_CAMERA = 'SWITCH_CAMERA';
export const SWITCH_CAMERA_COMPLETED = 'SWITCH_CAMERA_COMPLETED';
export const SWITCH_CAMERA_ERROR = 'SWITCH_CAMERA_ERROR';
export const TORCH = 'TORCH';
export const TORCH_COMPLETED = 'TORCH_COMPLETED';
export const TORCH_ERROR = 'TORCH_ERROR';
