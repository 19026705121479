import AssessmentDetails from "./AssessmentDetails";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Container} from 'reactstrap';
import styles from "./Timeslot.module.scss";

const AggregatedAssessments = (props) => {
  const sessionId = props.match.params.sessionId;
  const allAssessments = props.allAssessments

  return (
    <Container className={`${styles.assessment_items}`}>
        <p className={`text-center`}>
          <FormattedMessage id="assessments.all"/>
        </p>
        {
          allAssessments.map(function (assessment) {
            return (
              <AssessmentDetails
                key={`assessmentDetails${assessment.claimDetails}`}
                sessionId={sessionId}
                currentSessionId={assessment.sessionId}
                scheduledAssessmentDate={assessment.scheduledAssessmentDate}
                isActive={assessment.claimActive}
                claimNumber={assessment.claimNumber}
                claimDetails={assessment.claimDetails}>
              </AssessmentDetails>);
          })}
    </Container>
  );
};

export default AggregatedAssessments;
