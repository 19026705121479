import React, { Component } from 'react';
import styles from './Header.module.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { error: false };
    this.url = `${process.env.REACT_APP_API_URL}/brand/${props.sessionId}/logo`;
  }

  setError() {
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) return null;

    return (
      <header className="mb-3">
        <div className={`${styles.header} text-center`}>
          <img onError={this.setError.bind(this)} alt="Logo" className={`${styles.image}`} src={this.url} />
        </div>
      </header>
    );
  }
}

export default Header;
