function isIosWebView() {
  return navigator && /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
}

function isAndroidWebView() {
  return (
    'Android' in window ||
    'AndroidBridge' in window ||
    'webView' in window ||
    (window.document && 'ondeviceready' in window.document) ||
    (window.external && 'notify' in window.external) ||
    (navigator && /; wv/.test(navigator.userAgent))
  );
}

export default function isWebView() {
  return isIosWebView() || isAndroidWebView();
}
