import React from 'react';
import { FormattedMessage } from 'react-intl';
import CallAgainButton from 'components/common/call-again-button';

const Finish = (props) => (
  <div className="text-center">
    <p>
      <FormattedMessage id="finish.thankyou" />
    </p>

    <p>
      <FormattedMessage id="finish.reconnect" />
    </p>

    <div className="mt-5">
      <CallAgainButton sessionId={props.match.params.sessionId} />
    </div>
  </div>
);

export default Finish;
